const {
  REACT_APP_API_HOST,
  REACT_APP_AI_PROMPTS_API_HOST,
  REACT_APP_AI_PROMPTS_KEY,
  REACT_APP_ERROR_NOTIFICATION_SERVICE_URL,
  REACT_APP_MPMS_TOKEN,
  REACT_APP_WS_HOST,
  REACT_APP_GUARD,
} = process.env;

const config = {
  api: "https://cs.testing.muntra.com/api",
  aiPromptsApi: "https://cs.testing.muntra.com/chatbot",
  aiPromptsKey: "Hy9ziO5IQpvol9GOb9toPoew2Ho4lEgcJhfnRFArmFe1I",
  errorNotificationApi:
    "https://app.testing.muntra.com/log-api/error-notifications",
  guard: "support",
  mpmsToken: "YZmwhJ9UU7LiTL6dsixP91HlG57TYtXpSwrldupmjKykX5a82qy6Tza1WGn9i1we",
  wsServer: "wss://ws.testing.muntra.com",
};

const api = REACT_APP_API_HOST || window.ENV?.REACT_APP_API_HOST || config.api;
const aiPromptsApi =
  REACT_APP_AI_PROMPTS_API_HOST ||
  window.ENV?.REACT_APP_AI_PROMPTS_API_HOST ||
  config.aiPromptsApi;
const aiPromptsKey =
  REACT_APP_AI_PROMPTS_KEY ||
  window.ENV?.REACT_APP_AI_PROMPTS_KEY ||
  config.aiPromptsKey;
const errorNotificationApi =
  REACT_APP_ERROR_NOTIFICATION_SERVICE_URL ||
  window.ENV?.REACT_APP_ERROR_NOTIFICATION_SERVICE_URL ||
  config.errorNotificationApi;
const guard = REACT_APP_GUARD || window.ENV?.REACT_APP_GUARD || config.guard;
const mpmsToken =
  REACT_APP_MPMS_TOKEN || window.ENV?.REACT_APP_MPMS_TOKEN || config.mpmsToken;
const wsServer =
  REACT_APP_WS_HOST || window.ENV?.REACT_APP_WS_HOST || config.wsServer;

export default {
  api,
  aiPromptsApi,
  aiPromptsKey,
  errorNotificationApi,
  guard,
  mpmsToken,
  wsServer,
};
