export const TICKET_TYPES = {
  openHumanSupportTickets: "openHumanSupportTickets",
  allOpenTickets: "allOpenTickets",
  yourUnsolvedTickets: "yourUnsolvedTickets",
  unassignedTickets: "unassignedTickets",
  allTickets: "allTickets",
};

export const TICKET_TYPE_MAPPING = {
  0: [TICKET_TYPES.openHumanSupportTickets],
  1: [TICKET_TYPES.allOpenTickets],
  2: [TICKET_TYPES.yourUnsolvedTickets],
  3: [TICKET_TYPES.unassignedTickets],
};

export const TICKETS_VIEWS = {
  [TICKET_TYPES.openHumanSupportTickets]: {
    viewId: "1",
    path: "/tickets/view/1?page=1&sort_by=updated_at&order=asc&query=",
    title: "Asked for human support",
    status: "open",
    ticketQuery: { status: "open" },
  },
  [TICKET_TYPES.allOpenTickets]: {
    viewId: "2",
    path: "/tickets/view/2?page=1&sort_by=updated_at&order=asc&query=",
    title: "Open tickets",
    status: "open",
    ticketQuery: { status: "open" },
  },
  [TICKET_TYPES.yourUnsolvedTickets]: {
    viewId: "3",
    path: "/tickets/view/3?page=1&sort_by=updated_at&order=asc&query=",
    title: "Your unsolved tickets",
    status: "open",
    ticketQuery: { status: "open" },
  },
  [TICKET_TYPES.unassignedTickets]: {
    viewId: "4",
    path: "/tickets/view/4?page=1&sort_by=updated_at&order=asc&query=",
    title: "Unassigned",
    status: "unassigned",
    ticketQuery: { status: "unassigned" },
  },
  [TICKET_TYPES.allTickets]: {
    viewId: "5",
    path: "/tickets/view/5?page=1&sort_by=updated_at&order=desc&query=",
    title: "All tickets",
    status: "all",
    ticketQuery: { status: "all" },
  },
};
