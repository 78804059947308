import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { LOADING_STATUSES as STATUS } from "constants/common";
import { SupportTicketsService } from "services/support-tickets-service";
import {
  TICKET_TYPES,
  TICKET_TYPE_MAPPING as ticketTypeMapping,
} from "constants/tickets-views";
import { getItemFields } from "helpers/get-item-fields";

const getQueryParams = (responsibleIds) => [
  {
    has_asked_for_human_support: true,
    page: 1,
    status: "open",
    per_page: 1,
  },
  {
    page: 1,
    status: "open",
    per_page: 1,
  },
  {
    page: 1,
    status: "open",
    per_page: 1,
    responsible_ids: responsibleIds,
  },
  {
    page: 1,
    status: "unassigned",
    per_page: 1,
  },
];

export const loadTickets = createAsyncThunk(
  "GET_TICKETS",
  async ({
    hasAskedForHumanSupport,
    page,
    query,
    responsibleIds,
    status,
    sortBy,
    sortOrder,
  }) => {
    const dataConfig = {
      params: {
        has_asked_for_human_support: hasAskedForHumanSupport,
        page,
        status,
        sort_by: sortBy || "updated_at",
        order: sortOrder === "desc" ? "desc" : "asc",
        responsible_ids: responsibleIds,
        per_page: 10,
        include: "responsible,user,message_rooms,message_rooms.messages",
        query: query || "",
      },
    };
    const response = await SupportTicketsService.getTickets(dataConfig);

    const newData = response.data.data.map((item) => {
      const { relationships, ...rest } = item;
      const result = getItemFields(relationships, response.data.included);
      return { ...result, ...rest };
    });

    return {
      meta: response.data.meta,
      links: response.data.links,
      data: newData,
    };
  }
);

export const loadTicketsCount = createAsyncThunk(
  "GET_TICKETS_COUNT",
  async (responsibleIds) => {
    const queryParams = getQueryParams(responsibleIds);

    const getTickets = (params) => SupportTicketsService.getTickets({ params });

    const values = await Promise.all(
      queryParams.map((params, index) =>
        getTickets(params).then((response) => ({
          data: response.data,
          ticketType: ticketTypeMapping[index],
        }))
      )
    );

    return Object.fromEntries(
      values.map((item) => [
        item.ticketType,
        item.data?.meta?.pagination?.total || 0,
      ])
    );
  }
);

const initialState = {
  error: "",
  status: null,
  tickets: {},
  openedTickets: [],
  ticketsCount: {
    [TICKET_TYPES.openHumanSupportTickets]: 0,
    [TICKET_TYPES.allOpenTickets]: 0,
    [TICKET_TYPES.yourUnsolvedTickets]: 0,
    [TICKET_TYPES.unassignedTickets]: 0,
  },
};

const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    addOpenedTicket: (state, action) => {
      state.openedTickets = [
        ...state.openedTickets,
        {
          id: action.payload.id,
          room: action.payload.room,
          subject: action.payload.subject,
        },
      ];
    },
    removeOpenedTicket: (state, action) => {
      state.openedTickets = state.openedTickets.filter(
        (_, index) => index !== action.payload
      );
    },
  },
  extraReducers: {
    [loadTickets.pending]: (state) => {
      state.status = STATUS.loading;
    },
    [loadTickets.fulfilled]: (state, action) => {
      state.status = STATUS.success;
      state.tickets = action.payload;
    },
    [loadTickets.rejected]: (state, action) => {
      state.status = STATUS.failed;
      state.error = action.payload;
    },
    [loadTicketsCount.fulfilled]: (state, action) => {
      state.status = STATUS.success;
      state.ticketsCount = action.payload;
    },
  },
});

export const { addOpenedTicket, removeOpenedTicket } = ticketsSlice.actions;

export const getTicketsData = (state) => state.tickets.tickets;
export const getTicketsError = (state) => state.tickets.error;
export const getTicketsStatus = (state) => state.tickets.status;
export const getOpenedTickets = (state) => state.tickets.openedTickets;
export const getTicketsCount = (state) => state.tickets.ticketsCount;

export default ticketsSlice.reducer;
