import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import ReactHtmlParser from "react-html-parser";

import { LOADING_STATUSES as STATUS } from "constants/common";
import { LOCALES } from "constants/languages";
import useAutoWrite from "hooks/use-auto-write";
import { uuidGenerator } from "helpers/uuid-generator";
import {
  getInternalChatStatus,
  getMessages,
  postCompletionAndGetResponse,
  getChatMessage,
  updateChatMessage,
  chatBotReset,
  addChatMessage,
} from "redux/slices/internal-chatbot";
import { localesOptions } from "./assets";

import styles from "./styles.module.css";

const InternalChatbot = () => {
  const dispatch = useDispatch();
  const status = useSelector(getInternalChatStatus);
  const chatMessage = useSelector(getChatMessage);
  const messages = useSelector(getMessages);
  const chatContainerRef = useRef(null);
  const textareaRef = useRef(null);
  const disabled = useMemo(() => status === STATUS.loading, [status]);
  const [selectedLocale, setSelectedLocale] = useState(LOCALES.english);
  const uuid = useMemo(() => uuidGenerator(), []);

  const handleChange = useCallback(
    (value) => {
      if (!disabled) dispatch(updateChatMessage(value));
    },
    [dispatch, disabled]
  );
  const { setIsFocused } = useAutoWrite({
    textRef: textareaRef,
    textValue: chatMessage,
    handleChange,
    disabled,
  });

  const handleSubmit = useCallback(() => {
    if (!chatMessage.trim()) {
      return;
    }

    setIsFocused(false);
    dispatch(
      postCompletionAndGetResponse({
        prompt: chatMessage,
        locale: selectedLocale,
        uuid,
      })
    );
    dispatch(
      addChatMessage({
        type: "user-message",
        id: uuidGenerator(),
        message: chatMessage,
      })
    );
    dispatch(updateChatMessage(""));
  }, [chatMessage, setIsFocused, dispatch, selectedLocale, uuid]);

  const handleKeyDown = useCallback(
    (e) => {
      e.target.style.height = "inherit";
      e.target.style.height = `${e.target.scrollHeight}px`;
      if (e.keyCode === 13 && !(e.ctrlKey || e.shiftKey || e.altKey)) {
        e.preventDefault();
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  const scrollToBottom = useCallback(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  useEffect(() => {
    return () => {
      dispatch(chatBotReset());
    };
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <div className="rowSpaceBetween">
        <h2 className="sectionHeader">Internal chatbot</h2>
      </div>

      <div className="chatContainer" ref={chatContainerRef}>
        {messages.map((item) => (
          <div className="chatMessageContainer" key={item.id}>
            <div
              className={`chatBubble ${
                item.type === "user-message" && "chatBubbleSent"
              }`}
            >
              {item.type === "user-message"
                ? item.message
                : ReactHtmlParser(item.attributes.answer)}
            </div>
          </div>
        ))}
        {status === STATUS.loading && (
          <div className="ticketLoaderBox">
            <TailSpin
              width={60}
              height={60}
              color="#E0E0E0"
              ariaLabel="loading"
            />
          </div>
        )}
      </div>
      <div className="chatInputContainer rowSpaceBetween">
        <textarea
          ref={textareaRef}
          className="chatInput flexGrowOne"
          onKeyDown={handleKeyDown}
          value={chatMessage}
          onChange={(e) => handleChange(e.target.value)}
          disabled={status === STATUS.loading}
          onBlur={() => setIsFocused(false)}
        />
        <div className="chatButtons dontShrink rowEnd">
          <div
            className="buttonPrimary"
            onClick={handleSubmit}
            disabled={status !== STATUS.loading}
          >
            Send message
          </div>
        </div>
      </div>
      <div className={styles.languageContainer}>
        <select
          value={selectedLocale}
          onChange={(e) => setSelectedLocale(e.target.value)}
        >
          {localesOptions.map((option) => (
            <option key={option.code} value={option.code}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default InternalChatbot;
